<template>
  <i :data-feather="feather"></i>
</template>

<script>
import feather from "feather-icons";

export default {
  name: 'FeatherIcon',
  data: function () {
    return {
      feather: this.icon
    }
  },
  mounted: function() {
    this.$nextTick(function() {
      feather.replace();
    });
  },
  props: ['icon']
}
</script>
