<template>
  <div class="container">
    <div class="row">
      <select @change="onChange" class="form-control mg-b-25" v-model="church_id">
        <option value="">{{ $t('select one') }}</option>
        <option v-for="church in churches" v-bind:key="church.id" :value="church.id">{{church.name}}</option>
      </select>
    </div>
    <div class="row" v-if="loading">
      <div class="placeholder-paragraph aligned-centered">
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
    <div class="row" v-if="!loading">
      <table class="table" >
        <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Church</th>
          <th scope="col">Date</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="sermon in sermons" v-bind:key="sermon.id">
          <td>
            <router-link :to="{name: 'admin.sermons.edit', params: {id: sermon.id}, query: {church_id: church_id}}">{{ sermon.title }}</router-link>
          </td>
          <td>{{ sermon.church_name }}</td>
          <td>{{ sermon.date|format_date }}</td>
          <td>
            <fa :icon="['fa', 'trash-alt']" v-on:click="deleteSermon(sermon)"/>
          </td>
        </tr>
        </tbody>
      </table>

      <Pagination v-bind:meta="meta" v-on:pageChanged="onPageChanged"></Pagination>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import _ from 'lodash'
  import Pagination from '~/components/Pagination'

  export default {
    middleware: 'auth',
    components: {
      Pagination 
    },
    data: () => ({
      loading: true,
      church_id: "",
      meta: {},
      limit: 30,
      page: 1,
      sermons: []
    }),
    computed: {
      ...mapGetters({
        churches: 'admin/churches',
        churchById: 'admin/churchById'
      })
    },
    async created() {
      if (this.churches.length == 0) {
        await this.$store.dispatch('admin/fetchChurches');  
      }
      this.refresh();
    },
    watch: {
      // call again the method if the route changes
      '$route': 'refresh'
    },
    methods: {
      topics(sermon) {
        return _.join(_.map(sermon.topics, 'text'), ', ');
      },
      refresh() {
        if (this.$route.query.church_id) {
          this.church_id = this.$route.query.church_id;
          this.loading = true;
          this.$store.dispatch('admin/fetchSermons', {limit: this.limit, page: this.page, church_id: this.church_id})
            .then((data) => { 
              this.loading = false;
              this.meta = data.meta;
              this.sermons = data.sermons;
            });
        } else {
          this.church_id = "";
          this.sermons = [];
          this.meta = {};
          this.page = 1;
        }
      },
      onChange(event) {
        this.$router.push({ name: 'admin.sermons.index', query: {church_id: event.target.value}});
      },
      onPageChanged (page) {
        this.page = page;
        this.refresh();
      },
      async deleteSermon(sermon) {   
        try{
          await axios.delete('/api/v1/admin/sermons/' +  sermon.id);
          this.refresh();
        } catch (e) {
          // this.$router.push({name: 'accounts.church.invite'});
        }
      }
    }
  }
</script>
