<template>
  <nav aria-label="navigation" class="row justify-content-center" v-if="meta.last_page > 1">
    <ul class="pagination mg-b-0">
      <li class="page-item" :class="{disabled: page == 1}">
        <a class="page-link page-link-icon" href="#" v-on:click.prevent="setPage(meta.current_page - 1)"><FeatherIcon icon="chevron-left"/></a>
      </li>
      <li class="page-item" v-if="page > 3">
        <a class="page-link" href="#" v-on:click.prevent="setPage(1)">1</a>
      </li>
      <li class="page-item disabled" v-if="page > 4">
        <a class="page-link page-link-icon" href="#"><FeatherIcon icon="more-horizontal"/></a>
      </li>
      <template v-for="i in range(meta.current_page-2, meta.current_page+2)">
        <li class="page-item" :class="{active: meta.current_page == i}" v-bind:key="i">
          <a class="page-link" href="#" v-on:click.prevent="setPage(i)">{{i}}</a>
        </li>
      </template>
      <li class="page-item disabled" v-if="meta.last_page > page + 3">
        <a class="page-link page-link-icon" href="#"><FeatherIcon icon="more-horizontal"/></a>
      </li>
      <li class="page-item" v-if="meta.last_page > page + 3">
        <a class="page-link" href="#" v-on:click.prevent="setPage(meta.last_page)">{{meta.last_page}}</a>
      </li>        
      <li class="page-item" :class="{'disabled': page == meta.last_page}">
        <a class="page-link page-link-icon" href="#" v-on:click.prevent="setPage(meta.current_page + 1)"><FeatherIcon icon="chevron-right"/></a>
      </li>
    </ul>
  </nav>
</template>

<script>
  import _ from 'lodash'
  import FeatherIcon from '~/components/FeatherIcon'

  /**
    current_page: 1
    from: 1
    last_page: 3
    path: "http://dev.allsermons.vm/api/v1/sermons"
    per_page: "2"
    to: 2
    total: 5
 */
  export default {
    name: 'Pagination',
    props: {
      meta: {type: Object, default: null},
    },
    components: {
      FeatherIcon
    },
    data: () => ({
      page: 1,
    }),
    methods: {
      range(start, end){
        return _.range(Math.max(start, 1), Math.min(end + 1, this.meta.last_page + 1));
      },
      setPage(n) {
        this.page = n;
        this.$emit('pageChanged', n);
      }
    }
  }
</script>
